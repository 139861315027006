@import '../../base/sizes';
@import 'logo';
@import 'user';
@import 'menu';

.aside {
  background-color: var(--card-background-color);
  background-color: var(--card-background-color);
  color: $text-foreground;
  color: var(--text-primary-color);
  transition: all 0.2s;
  display: flex;
  align-items: end;
  justify-items: center;
  height: 100%;
  position: fixed;
  overflow: unset;
  z-index: 1022;
  max-width: $aside-bar-size;
  border-right: 1px solid var(--separator-color);

  .aside-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 100vh;
    align-items: center;

    .aside-menu {
      display: flex;
      justify-content: center;
      align-items: center;
    }


    .user-action {
      span {
        &::before {
          font-size: $title-font-size;
        }
      }
    }

  }


  .close-aside-menu {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: $subtitle-font-size;
    background-color: rgba(0,0,0,0.3);
    padding: 5px 10px;
    border-radius: 5px;
  }

  .menu-user {
    align-items: end;
    display: flex;
    justify-items: center;
    width: 100%;

    section {
      display: grid;
      align-items: end;
      justify-items: center;
      width: 100%;
    }
  }

  .user-nav-content {
    width: 150px;
    position: absolute;
    bottom: 25px;
    left: 50px;
    background: var(--card-background-color);
    background: var(--card-background-color);
    padding: 22px;
    border-radius: 8px;
  }


  @media (max-width: $small-width) {
    z-index: 36;
    position: fixed;
    width: 0;
    right: 0;
    left: 0;
    background-color: var(--card-background-color);
    background-color: var(--card-background-color);
    top: 0;
    bottom: 1px;
    height: 100%;
    overflow-x: hidden;
    max-width: $aside-bar-size;
    justify-content: space-around;

    .aside-body {
      justify-content: space-around;
    }

    .user-nav-content {
      position: fixed;
      bottom: 25px;
      left: 50px;
    }

    .menu-list-item {
      visibility: hidden;
      opacity: 0;
    }

    &.aside-toggle {
      top: $header-bar-size;
      width: $aside-bar-size; // width by % don't work in safari

      .menu-list-item {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  @media (min-width: $small-width) {
    .close-aside-menu {
      display: none;
    }
  }
}
