@import './base/base';
@import './components/components';
@import './layout/layout';
@import './views/login';
@import './views/score';

html,
body,
#root {
  font-family: 'Lato', sans-serif;
  font-size: $text-font-size;
  width: 100%;
  height: 100%;
  color: var(--text-secondary-color);
  background-color: var(--app-background-color);
}

* {
  margin: 0;
  padding: 0;
  border-radius: 0;
}

main.main-container {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}
