@import '../base/colors';

.badget {
  background-color: #ddd;
  padding: 6px;
  font-size: $subtext-font-size;
  border-radius: 24px;
  width: 100%;
  max-width: 128px;
  transition: width .3s ease-out;
  text-align: center;
  color: var(--card-background-color);

  span {
    user-select: none;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 800;
    width: 100%;
  }

  &-success {
    padding: 6px 12px 6px 12px;
    font-weight: bolder;
    color: var(--success-color);
    background-color: rgba($color: var(--success-color), $alpha: 0.1);
  }

  &-warning {
    padding: 6px 12px 6px 12px;
    font-weight: bolder;
    color: var(--warning-color);
    background-color: rgba($color: var(--warning-color), $alpha: 0.1);
  }

  &-danger {
    padding: 6px 12px 6px 12px;
    font-weight: bolder;
    color: var(--danger-color);
    background-color: rgba($color: var(--danger-color), $alpha: 0.1);
  }

  &-info {
    padding: 6px 12px 6px 12px;
    font-weight: bolder;
    color: var(--info-color);
    background-color: rgba($color: var(--info-color), $alpha: 0.1);
  }

  &-lg {
    font-size: $subtitle-font-size;
    padding: 12px 24px;
    max-width: 346px;
  }
}
