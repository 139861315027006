@import '../base/colors';

.status {
  /**
  * Tickets status
  */
  &-post {
    // Postagem
    color: var(--info-color);
    background-color: rgba($color: $azureradiance, $alpha: 0.1);
  }
  &-receivement {
    // Recebimento
    color: var(--neoncarrot-color);
    background-color: rgba($color: $gold, $alpha: 0.1);
  }
  &-check {
    // Conferência
    color: var(--sunsetorange-color);
    background-color: rgba($color: $sunsetorange, $alpha: 0.1);
  }
  &-solicitation {
    // Solicitação
    color: var(--japaneselaurel-color);
    background-color: rgba($color: $japaneselaurel, $alpha: 0.1);
  }
}
