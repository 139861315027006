@import '../../base/sizes';

.logo {
  user-select: none;

  &-body {
    display: flex;
    width: $aside-bar-size;
    height: $header-bar-size;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--separator-color);
    background-color: var(--card-background-color);
    transition: all 0.3s ease;

    @media (min-width: $medium-width) {
      &:hover {
        transition: all 0.3s ease;
        background-color: var(--primary-highlight);
      }
    }

    @media (max-width: $small-width) {
      height: auto;
      width: auto;
    }
  }
}
