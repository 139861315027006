.header-user {
  position: relative;

  .header-user-nav {
    color: #333;
    position: absolute;
    right: 0px;
    top: 40px;
    min-width: 250px;
    z-index: 35;

    &:after {
      content: '';
      background-color: #fff;
      width: 10px;
      height: 10px;
      position: absolute;
      top: -3px;
      right: 5px;
      transform: rotate(140deg);
    }

    .user-nav-content {
      padding: 12px;
      box-shadow: 0 32px 64px rgba(0, 0, 0, 0.3);

      li {
        font-size: $subtitle-font-size;
        cursor: pointer;
        padding: 15px 0;

        &:first-of-type {
          padding-top: 0px;
        }

        &:last-of-type {
          border: none;
        }
      }
    }
  }
}
