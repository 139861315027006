.score {
  width: 100%;
  max-width: 1200px;
  margin: 100px auto;

  &__content {
    .table-head,
    .table-item {
      display: grid;
      grid-template-columns:
        minmax(162px, 1fr) minmax(78px, 1fr) minmax(86px, 1fr) minmax(90px, 1fr)
        1fr minmax(120px, 1fr);

      &-col {
        box-sizing: border-box;
        text-align: center;
        justify-content: center !important;
        font-size: $text-font-size;
      }
    }
  }
}
