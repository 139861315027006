a {
  color: var(--text-primary-color);
}

h1, h2 {
  color: var(--text-primary-color);

  small {
    margin-left: 10px;
    font-size: $text-font-size;
  }
}


h1 {
  font-size: $huge-font-size;
  font-weight: 900;
  position: relative;
  margin-bottom: 40px;
}

h1 {
  font-size: $huge-font-size;
  font-weight: 900;
  position: relative;
  margin-bottom: 40px;

  &.no-bar {
    &::after {
      background-color: rgba($color: #000000, $alpha: 0.0);
    }
  }
}

h2 {
  font-size: $title-font-size;
  margin-bottom: 20px;

  &.bordered {
    border-bottom: 1px solid var(--separator-color);
    padding-bottom: 10px;
  }
}

h3 {
  font-weight: 400;
  font-size: $subtitle-font-size;
  color: #555;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-white {
  color: #fff;
}

.text-xs {
  font-size: $subsubtext-font-size;
  color: var(--text-tertiary-color);
}

.text-sm {
  font-size: $text-font-size;
  color: var(--text-tertiary-color);
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}


.label {
  font-size: $subtext-font-size;
  color: var(--text-secondary-color);
  width: 100%;
  display: block;
}

.required {
  color: $tf-coral;
}

.actions {
  .fas {
    opacity: 0.3;
  }
}
