@import '../base/colors';

$button-padding: 0px 14px;

.btn-reset {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
}

.btn-control {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 6px 0 6px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 767.98px) {
    button {
      display: block;
      width: 100%;
      margin-bottom: 10px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.btn {
  display: flex;
  background-color: var(--card-background-color);
  color: var(--text-primary-color);
  border: 2px solid var(--separator-color);
  padding: 10px 18px;
  margin: 0 4px 0 4px;
  border-radius: 21px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.5s ease;
  justify-content: center;
  align-items: center;
  width: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;

  &:hover {
    transition: background-color 0.3s ease;
    background-color: var(--separator-color);
  }

  &.btn-icon {
    padding: 0;
    margin: 2px;
    min-width: 36px;
    min-height: 36px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }

  span {
    margin-right: 8px;
  }

  &.btn-primary {
    color: var(--primary-color);

    &--dark {
      color: var(--card-background-color);
      background-color: var(--primary-color-dark);
      border: none;
    }

    &:hover {
      transition: all 0.3s ease;
      color: var(--card-background-color);
      border: 2px solid var(--primary-color);
      background-color: var(--primary-color);
    }

    &__nfd {
      background-color: var(--primary-color-light);
      color: var(--card-background-color);
      border: none;

      &:hover {
        transition: all 0.3s ease;
        color: var(--card-background-color);
        background-color: var(--primary-color-light);
      }
      &:disabled {
        background-color: var(--darkgray-ellipsis-color);
        color: var(--darkgray-color);
        pointer-events: none;

        svg {
          fill: var(--darkgray-color);
        }
      }
    }
  }

  &.btn-success {
    color: $tf-green;
    background-color: var(--card-background-color);

    span {
      color: $tf-green;
    }

    &:hover {
      transition: all 0.3s ease;
      border: 2px solid $tf-green;
      color: var(--card-background-color);
      background-color: $tf-green;

      span {
        transition: color 0.2s ease-in;
        color: var(--card-background-color);
      }
    }
  }

  &.btn-danger {
    color: $tf-coral;
    background-color: var(--card-background-color);

    span {
      color: $tf-coral;
    }

    &:hover {
      transition: all 0.3s ease;
      border: 2px solid $tf-coral;
      color: var(--card-background-color);
      background-color: $tf-coral;

      span {
        color: var(--card-background-color);
        transition: all 0.2s ease-in;
      }
    }
  }

  &.btn-info {
    color: var(--caribbeangreen-color);
    background-color: var(--card-background-color);
  }

  &.btn-warning {
    color: var(--dimgray-color);
    background-color: var(--card-background-color);

    span {
      color: var(--dimgray-color);
    }

    &:hover {
      transition: all 0.3s ease;
      border: 2px solid var(--dimgray-color);
      color: var(--card-background-color);
      background-color: var(--dimgray-color);

      span {
        color: var(--card-background-color);
        transition: all 0.2s ease-in;
      }
    }
  }

  &.btn-md {
    height: 38px;
    padding: 10px 18px;
    font-size: $text-font-size;
    width: fit-content;
  }

  &.btn-sm {
    height: 36px;
    padding: 8px 15px;
    font-size: $text-font-size;
    width: fit-content;
  }

  &.btn-xs {
    height: 26px;
    padding: 5px 10px;
    font-size: $subtext-font-size;
    width: fit-content;
  }

  &.btn-small-icon {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    padding: 0;

    span {
      font-size: $subtext-font-size;
      margin: 0;
    }
  }

  &.btn-close {
    background-color: transparent;
    border: 0;
  }

  &.btn-block {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    background: $gray-01;
    box-shadow: none;
    color: #6d6d6d;

    &:hover {
      background: $gray-01;
      border: 2px solid $gray-01;
      color: #6d6d6d;
    }
  }
}

.simple-btn {
  color: $text-foreground;
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in;

  &:hover {
    transition: background-color 0.3s ease-in;
    background-color: $gray-01;
  }
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: $button-padding;
  border-radius: 20px;
  color: var(--text-secondary-color);
  background-color: transparent;
  border: 1px solid var(--separator-color);
  font-weight: bold;
  line-height: 17px;
  font-size: $text-font-size;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  opacity: 1;
  transition: all 0.2s ease-out;
  cursor: pointer;
  box-sizing: border-box;

  &[data-disabled='on'] {
    color: var(--darkgray-color) !important;
    background-color: var(--separator-color) !important;
    border: 1px solid transparent;
  }

  &__action {
    font-weight: bold;
    color: var(--text-secondary-color);
    background-color: transparent;
    padding: $button-padding;
    border: 1px solid transparent;
    border-radius: 20px;
    cursor: pointer;
    transition: all 100ms ease-out;

    i {
      line-height: 14px;
      padding-right: 8px;
    }

    &.success {
      color: var(--card-background-color);
      background-color: var(--success-color);

      svg {
        fill: var(--success-color);
      }
    }

    &.warning {
      color: var(--card-background-color);
      background-color: var(--warning-color);

      svg {
        fill: var(--warning-color);
      }
    }

    &.danger {
      color: var(--card-background-color);
      background-color: var(--danger-color);

      svg {
        fill: var(--danger-color);
      }
    }

    &.append {
      color: var(--card-background-color);
      background-color: var(--info-color);

      svg {
        fill: var(--info-color);
      }
    }

    &.primary {
      color: var(--card-background-color);
      background-color: var(--primary-color-light);
      transition: background-color 100ms ease-out;

      svg {
        fill: var(--primary-color-light);
      }

      &:hover {
        background-color: var(--primary-color-lightless);
        transition: background-color 100ms ease-in;
      }

      &:active {
        transform: scale(0.95);
        transition: all 100ms ease;
      }
    }
  }

  &__secondary {
    font-weight: bold;
    color: var(--text-secondary-color);
    background-color: transparent;
    padding: $button-padding;
    border: 1px solid transparent;
    border-radius: 20px;
    cursor: pointer;
    transition: all 100ms ease-out;

    &:hover {
      background-color: var(--separator-color);
      transition: all 200ms ease-in;
    }

    &:focus {
      border: 1px solid var(--separator-color);
      transition: all 100ms ease-in;
    }

    &:active {
      background-color: var(--separator-color);
      transition: all 100ms ease-in;
    }
  }

  i + p {
    line-height: 14px;
    padding-left: 8px;
  }

  svg {
    margin-right: 8px;
  }

  &__group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    button {
      margin-right: 6px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &:disabled {
    background-color: var(--separator-color) !important;
    color: var(--text-secondary-color) !important;
    cursor: not-allowed;

    svg {
      fill: var(--text-secondary-color) !important;
    }
  }

  &.icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    border-radius: 50%;
    box-sizing: border-box;

    svg {
      margin: 0;
    }

    i {
      padding: 0 !important;
    }

    &-small {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 !important;
      width: 22px;
      height: 22px;
      min-width: 22px;
      min-height: 22px;
      max-width: 22px;
      max-height: 22px;
      border-radius: 50%;
      font-size: $subsubtext-font-size;

      i {
        padding: 0 !important;
      }
    }

    &.only-icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      font-size: $subtitle-font-size;

      i {
        padding: 0 !important;
      }
    }
  }
  &.rounded {
    border-radius: 20px;
  }

  &.form {
    border-radius: 20px;
    border: 1px solid var(--separator-color);
    background-color: var(--card-background-color);
    color: var(--text-tertiary-color);
    transition: all 100ms ease-in;

    &:hover {
      background-color: var(--separator-color);
      transition: all 100ms ease-in;
    }
  }

  &.success {
    color: var(--success-color);

    svg {
      fill: var(--success-color);
    }
  }

  &.warning {
    color: var(--card-background-color);
    background-color: var(--warning-color);
  }

  &.danger {
    color: var(--danger-color);

    svg {
      fill: var(--danger-color);
    }
  }

  &.append {
    color: var(--info-color);

    svg {
      fill: var(--info-color);
    }
  }

  &.primary {
    color: var(--primary-color-light);

    svg {
      fill: var(--primary-color-light);
    }

    &:hover {
      background-color: var(--separator-color);
      transition: background-color 100ms ease-in;
    }
  }

  &.primary-full {
    border: 1px solid var(--primary-color);
    color: var(--card-background-color);
    background-color: var(--primary-color);
    transition: background-color 100ms ease-out;

    &:hover {
      border: 1px solid var(--primary-color-lightless);
      background-color: var(--primary-color-lightless);
      transition: background-color 100ms ease-in;
    }

    &:active {
      transform: scale(0.95);
      transition: all 100ms ease;
    }
  }

  &:active {
    opacity: 1;
    transform: scale(0.95);
    transition: all 0.1s ease-in;
  }

  &:hover {
    opacity: 0.9;
    transition: all 0.1s ease-in;
  }
}
