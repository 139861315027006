$limit-mobile-width: 640px;
$small-width: 768px;
$medium-width: 992px;
$large-width: 1200px;

$mid-modal-size: 480px;

// Borders
$card-border-radius: 8px;
$tabs-border-radius: 16px;

// bars
$header-bar-size: 50px;
$aside-bar-size: 50px;

// modals
$modal-min-width: 420px;
$modal-max-width: 920px;
$modal-xxl-width: 1200px;
$modal-xxxl-width: 1400px;
$modal-content-max-width: 820px;

// buttons
$sm-action-btn: 28px;
$ms-action-btn: 36px;

$container-medium-width: 725px;
$container-small-width: 520px;
$container-small-height: 418px;

// Fonts
$huge-font-size: 32px;
$title-font-size: 20px;
$subtitle-font-size: 16px;
$text-font-size: 13px;
$subtext-font-size: 12px;
$subsubtext-font-size: 10px;
