@import '../base/colors';

input {
  z-index: 9;
  outline: none;
}

.input {
  &-text {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    box-sizing: border-box;
    width: 100%;
    min-height: 44px;
    max-height: 64px;

    #input-label {
      font-size: $subtext-font-size;
      color: var(--text-secondary-color);
      pointer-events: none;
    }

    #input-error {
      transition: all 0.2s ease;
      margin-top: 4px;
      font-size: $subtext-font-size;
      color: var(--danger-color);
    }

    #dangerous-text {
      width: 100%;
      padding: 2px 0 4px;
      margin-top: 6px;
      margin-bottom: 6px;
    }

    &__action {
      position: absolute;
      right: 0;
      top: 14px;
      z-index: 99;
    }

    input {
      width: inherit;
      background-color: transparent;
      color: var(--text-primary-color);
      padding: 2px 0 4px;
      border: none;
      border-bottom: 1px solid var(--separator-color);
      margin-top: 6px;
      margin-bottom: 6px;

      &:focus {
        transition: all 0.2s ease-in;
        border-bottom: 1px solid var(--primary-color-light) !important;

        + label {
          color: var(--primary-color-light) !important;
        }
      }
    }

    &[data-status='error'] {
      #input-label {
        transition: color 0.2s ease;
        color: var(--danger-color);
      }

      input {
        transition: all 0.2s ease-in-out;
        border: 1px solid var(--danger-color);
      }
    }

    &[data-dangerous='on'] {
      padding: 0;
      max-height: unset;
    }

    &[data-status='normal'] {
      #input-label {
        transition: color 0.2s ease;
        color: var(--text-secondary-color);
      }

      input {
        transition: all 0.2s ease-in-out;
        border-bottom: 1px solid var(--separator-color);
      }
    }

    input[type='date'] {
      padding-inline-start: 0;
      height: 15px;
      font-size: 13px;
      font-family: sans-serif;
    }
  }

  &-text {
    min-height: 60px;
  }
}

.select-with-search {
  position: relative;
}

.select-w-search {
  position: relative;

  &__control {
    z-index: 9999;
    //background-color: var(--card-background-color) !important;
    border-bottom: 1px solid var(--separator-color) !important;

    * {
      //background-color: var(--card-background-color);
    }
  }

  &__option {
    background-color: var(--separator-color);
  }

  &__menu {
    z-index: 99999999 !important;
    background-color: var(--card-background-color);

    * {
      background-color: var(--card-background-color);
    }
  }
}
