@import '../base/sizes';

$filter-max-width: 380px;

.filter-container {
  position: fixed;
  top: $header-bar-size;
  right: 0;
  bottom: 0;
  flex: 1;
  min-width: $filter-max-width;
  max-width: $filter-max-width;
  border: none;
  border-radius: 0;
  background-color: var(--card-background-color);
  z-index: 100;
  animation: slide-bottom 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

  button {
    outline: none;
  }

  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--separator-color);
    box-sizing: border-box;

    button > svg {
      height: 17px;
      margin-top: 2px;
    }

    &__action-buttons {
      button {
        margin: 0 4px 0 4px;
        border: none;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        font-size: $subtitle-font-size;
        color: var(--text-primary-color);
        background-color: transparent;
        cursor: pointer;
        transition: background-color 0.2s ease-out;

        &:hover {
          transition: background-color 0.2s ease-in;
          background-color: var(--separator-color);
        }

        &:last-of-type {
          margin-left: 12px;
        }
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: hidden;
    padding: 24px;

    button > svg {
      height: 15px;
    }
  }

  @media (max-width: $limit-mobile-width) {
    position: fixed;
    top: 64px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    border-radius: 0;
    width: 100%;
    border-bottom: 1px solid #EEE;
    box-shadow: 0 8px 16px rgba($color: black, $alpha: 0.3);
    overflow-y: auto;
  }
}

@keyframes slide-bottom {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  50% {
    opacity: 50%;
    box-shadow: 0 0 32px rgba(0,0,0,0.1);
  }
  100% {
    transform: translateX(0);
    box-shadow: 0 0 64px rgba(0,0,0,0.2);
  }
}
