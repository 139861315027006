.form-login, .form-recovery {
  display: grid;
  grid-row-gap: 20px;

  input {
    background-repeat: no-repeat;
    background-size: 10px auto;
    background-position: 20px center;
    box-sizing: border-box;
    border: 1px solid var(--separator-color);
    min-height: 38px;
    padding: 0 15px;
    border-radius: 4px;
    padding-left: 40px;
    font-size: 13px;
    margin: 0;

    &:focus {
      border: 2px solid var(--primary-color) !important;
    }
  }

  span {
    &.input-icon {
      position: absolute;
      width: 30px;
      height: 12px;
      top: 13px;
      left: 13px;
      z-index: 9;
      text-align: center;
      img {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 575.98px) {
    width: 80%;
  }

  .fields {
    display: grid;
    grid-row-gap: 20px;
  }

  .actions {
    text-align: center;
  }

  .input-text {
    min-height: 0;
  }
}
