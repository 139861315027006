@import './components/form-login';

.login {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 10000;

  .login-logo {
    img {
      width: 280px;
    }
  }

  button[type=submit] {
    box-shadow: none;
    border-radius: 20px;
    padding: 10px 0px;
    background: var(--secondary-color);
  }

  &__background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__left-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    max-width: 570px;
    box-sizing: border-box;
    background-color: white;
    z-index: 1;

    &__logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-bottom: 45px;
    }

    &__content {
      width: 100%;
      max-width: 310px;
      box-sizing: border-box;
    }

    &__middle {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 20px;
    }
  }
}
