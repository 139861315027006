@import 'colors';

:root {
  --app-background-color: #f5f6fa;
  --card-background-color: #ffffff;
  --separator-color: #e6e8f1;
  --text-primary-color: #000;
  --text-secondary-color: #686868;
  --text-tertiary-color: #444;
  --text-danger-color: #f44336;
  --text-success-color: #219653;
  --modal-background-color: rgba(0, 0, 0, 0.4);

  --primary-color-darkest: #3d2766;
  --primary-color-dark: #532f86;
  --primary-color-darkless: #62449a;
  --primary-color: #5C4D85;
  --primary-color-lightless: #8577A2;
  --primary-color-light: #8577A2;
  --secondary-color: #E68174;
  --disable-background-color: #686868;
  --primary-highlight: rgba(92, 77, 133, 0.2);
  --header-disabled-color: #C9CFDB;

  --success-color: #219653;
  --warning-color: #fcd43b;
  --danger-color: #f44336;
  --info-color: #2d77ff;

  //status colors
  --darkgray-color: #9f9fa0;
  --darkgray-ellipsis-color: #C4C4C4;
  --dimgray-color: #585959;
  --gold-color: #fcd43b;
  --buddhagold-color: #cc9900;
  --neoncarrot-color: #ff9934;
  --coral-color: #ff794a;
  --cerise-color: #de3163;
  --salmon-color: #ff7b6e;
  --sunsetorange-color: #ff4954;
  --tiamaria-color: #ce2c0d;
  --jacarta-color: #3d2766;
  --royalpurple-color: #62449a;
  --blue-magenta-color: #CAADFF;
  --smalt-color: #003399;
  --sanmarino-color: #4068aa;
  --azureradiance-color: #0099ff;
  --caribbeangreen-color: #00c4b1;
  --japaneselaurel-color: #30d64b;
  --camarone-color: #098f15;
}

[data-theme='dark'] {
  --app-background-color: #111114;
  --card-background-color: #202022;
  --separator-color: rgba(230, 232, 241, 0.1);
  --text-primary-color: #eee;
  --text-secondary-color: #999999;
  --text-tertiary-color: #bbb;
  --text-danger-color: #f4695a;
  --text-success-color: #459673;
  --modal-background-color: rgba(0, 0, 0, 0.9);

  --primary-color-darkest: #3d2766;
  --primary-color-dark: #532f86;
  --primary-color-darkless: #62449a;
  --primary-color: #8E80AB;
  --primary-color-lightless: #8577A2;
  --primary-color-light: #8577A2;
  --secondary-color: #E68174;
  --disable-background-color: #686868;
  --primary-highlight: rgba(92, 77, 133, 0.2);
  --header-disabled-color: rgba(230, 232, 241, 0.1);

  --success-color: #59cba4;
  --warning-color: #fce753;
  --danger-color: #ff8c78;
  --info-color: #85abff;
  // status colors
  --darkgray-color: #9f9fa0;
  --darkgray-ellipsis-color: #686868;
  --dimgray-color: #7d7e7e;
  --gold-color: #fcd43b;
  --buddhagold-color: #cc9900;
  --neoncarrot-color: #ff9934;
  --coral-color: #ff794a;
  --cerise-color: #de3163;
  --salmon-color: #ff7b6e;
  --sunsetorange-color: #ff4954;
  --tiamaria-color: #f64624;
  --jacarta-color: #8a58e5;
  --royalpurple-color: #b994ff;
  --blue-magenta-color: #CAADFF;
  --smalt-color: #4a72d4;
  --sanmarino-color: #4068aa;
  --azureradiance-color: #0099ff;
  --caribbeangreen-color: #00c4b1;
  --japaneselaurel-color: #30d64b;
  --camarone-color: #098f15;
}
