@import "../base/sizes";

.header-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  right: 0;
  left: $aside-bar-size;
  position: fixed;
  height: $header-bar-size;
  background-color: var(--card-background-color);
  color: #333;
  border-bottom: 1px solid var(--separator-color);
  z-index: 1023;

  @media (max-width: $medium-width) {
    left: 0;

    #container {
      display: flex;
      flex-direction: row;
    }
  }

  @media (min-width: $medium-width) {
    #container {
      display: flex;
      flex-direction: row;
    }

    .logo {
      display: none;
    }
  }
}
