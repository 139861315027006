.pagination {
  margin-top: 20px;

  &-current {
    span {
      margin-left: 10px;
      color: var(--text-tertiary-color);
    }

    input {
      padding: 0 5px;
      font-size: 12px;
      height: 25px;
      width: auto;
      box-sizing: border-box;
      border: 1px solid var(--separator-color);
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0) !important;

      &:focus {
        background-color: var(--card-background-color);
      }
    }
  }

  &-action {
    margin-right: 10px;

    input {
      text-align: center;
      max-width: 50px;
    }

    button > svg {
      height: 17px;
      margin-top: 2px;
    }
  }

  &-button {
    cursor: pointer;
    background: none;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    color: var(--text-secondary-color);
    transition: background-color 0.3s ease;
    outline: none;

    &:hover {
      transition: background-color 0.3s ease;
      background-color: var(--separator-color);
    }
  }
}
