// Examples for devices
// @media (max-width: 575.98px) {}
// @media (min-width: 576px) and (max-width: 767.98px) {}
// @media (min-width: 768px) and (max-width: 991.98px) {}
// @media (min-width: 992px) and (max-width: 1199.98px) {}
// @media (min-width: 1200px) {}

@media (max-width: 575.98px) {
  .hide-xs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hide-sm {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .hide-md {
    display: none;
  }
}

@media (min-width: 992px) {
  .hide-lg {
    display: none;
  }
}
