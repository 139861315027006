@import '../base/colors';
@import '../base/sizes';

.breadcrumb {
  display: flex;
  flex-direction: row;
  margin-left: 16px;
  align-items: center;
  font-size: $text-font-size;
  min-width: 200px;

  &-item {
    cursor: pointer;
    color: $text-foreground;
    color: var(--text-secondary-color);
    font-weight: bold;
    transition: color 0.3s ease-out;

    a {
      color: $text-foreground;
      color: var(--text-tertiary-color);
      margin-right: 16px;
    }

    i {
      margin-right: 16px;
    }

    &:hover {
      & a {
        transition: color 0.3s ease-in;
        color: $primary;
        color: var(--primary-color);
      }
    }

    &.active {
      a {
        color: $primary;
        color: var(--primary-color);
      }
    }

    @media (max-width: $small-width) {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
