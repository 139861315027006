.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-4 {
  z-index: 4;
}

.z-index-8 {
  z-index: 8;
}

.z-index-16 {
  z-index: 16;
}

.z-index-32 {
  z-index: 32;
}

.z-index-64 {
  z-index: 64;
}