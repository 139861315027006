@import '../base/colors';

$card-height: 32px;

.status-card-list-container {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  flex-flow: row;
}

.status-card-container {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  background-color: var(--card-background-color);
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 12px;
  border: 1px solid var(--separator-color);
  padding: 12px;
  margin: 12px 6px 12px 6px;
  width: 182px;
  min-width: 182px;
  min-height: $card-height;
  max-height: $card-height;
  box-shadow: 0 0 0 rgba($color: #000000, $alpha: 0);
  transition: box-shadow 0.3s ease-out;

  span {
    background-color: saddlebrown;
    height: 42px;
    width: 42px;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    i {
      color: white;
      font-size: $title-font-size;
    }
  }

  img {
    height: 36px;
    width: 36px;
    margin-right: 12px;
  }

  div {
    width: 128px;
  }

  div > h3 {
    color: var(--text-tertiary-color);
  }

  div > p {
    color: var(--text-foreground-color);
    width: 128px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  &:hover {
    transition: box-shadow 0.3s ease-in;
    box-shadow: 0 4px 8px rgba($color: #000000, $alpha: 0.1);
  }

  &-tip {
    position: absolute;
    top: -8px;
    right: -6px;
    padding: 2px 5px 2px 5px;
    border-radius: 10px;
    font-size: small;
    font-weight: bold;
    color: var(--card-background-color) !important;
    background-color: var(--danger-color);
  }

  &--solicitation {
    // Solicitação
    span {
      background-color: var(--japaneselaurel-color);
    }
  }
  &--check {
    // Conferência
    span {
      background-color: var(--sunsetorange-color);
    }
  }
  &--post {
    // Postagem
    span {
      background-color: var(--azureradiance-color);
    }
  }
  &--receivement {
    // Recebimento
    span {
      background-color: var(--gold-color);
    }
  }

  &--other {
    span {
      background-color: saddlebrown;
    }
  }

  &-square {
    width: $card-height;
    min-width: $card-height;

    svg {
      transform: scale(1.2);
      fill: var(--darkgray-color);
  
      &[data-active="on"] {
        fill: var(--primary-color-light);
      }
    }

    i {
      color: #bfc5d1;
    }

    p {
      color: var(--text-primary-color);
      font-weight: 600;
    }
  }
}
