@import './colors';
@import './vars';
@import './sizes';
@import './font';
@import './spaces';

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.full-width {
  width: 100%;
}