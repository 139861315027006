.dropdown-button {
  position: relative;
  z-index: 999;

  &[data-disabled="on"] {
    opacity: 0.6;
    pointer-events: none;
  }

  &__default-action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__default-action-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;

    &[data-no-actions='on'] {
      border-top-right-radius: 21px;
      border-bottom-right-radius: 21px;
      //border-radius: 21px;
    }
  }

  &__default-action-more-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-left: 0;

    i {
      margin-top: -5px;
    }

    &[data-no-actions='on'] {
      display: none;
    }
  }

  &__list {
    position: absolute;
    width: 100%;
    max-width: 280px;
    min-width: 270px;
    box-sizing: border-box;
    list-style: none;
    z-index: 9999999;
    padding: 6px;
    background-color: var(--card-background-color);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-block-start: 8px;
    transition: all 150ms ease;

    &[data-show="off"] {
      opacity: 0;
      transition: all 150ms ease;
      display: none;
    }

    &__item {
      padding: 12px 4px;
      background-color: transparent;
      width: 100%;
      text-align: start;
      color: var(--text-primary-color);
      cursor: pointer;
      border: none;
      transition: background-color 100ms ease-in;

      &:hover {
        background-color: var(--primary-highlight);
        transition: background-color 100ms ease-in;
      }
    }
  }
}
