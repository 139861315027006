@import '../base/colors';

.table {
  overflow-x: auto;
  overflow-y: hidden;

  .table-default {
    // min-width: 480px;
    min-width: 1024px;

    &__min-width-sm {
      min-width: 320px;
    }

    &__min-width-md {
      min-width: 480px;
    }

    &__min-width-hd {
      min-width: 720px;
    }

    &__min-width-fhd {
      min-width: 1024px;
    }

    &__min-width-high {
      min-width: 1280px;
    }

    &-empty {
      text-align: center;
      min-width: auto;
      overflow: hidden;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      font-size: $subtitle-font-size;
      min-height: 100px;
      color: var(--text-foreground-color);

      img {
        filter: grayscale(100);
        opacity: 0.2;
        margin-bottom: 40px;
      }
    }

    .table-head,
    .table-item {
      display: grid;

      &-col {
        padding: 10px;
      }
    }

    .table-head {
      user-select: none;
      padding: 2px 10px;
      &-col {
        display: flex;
        color: var(--text-secondary-color);
        font-size: $text-font-size;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .table-item {
      justify-content: center;
      align-items: center;
      margin: 4px 4px 8px 4px;
      background-color: var(--card-background-color);
      border-radius: 6px;
      height: 100%;
      padding: 0 4px;
      transition: all 0.3s ease-out;

      &-col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        word-break: break-word;
        text-transform: capitalize;
      }

      &[data-no-hover='on'] {
        &:hover {
          transition: all 0.3s ease-in;
          box-shadow: 0px 4px 8px transparent;
        }
      }

      &:hover {
        transition: all 0.3s ease-in;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      }
    }

    .table-item-center {
      &-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background-color: #eee;
      }
    }
  }
}

.width-col-status {
  min-width: minmax(162px, 100%);
}

.width-col-id {
  min-width: minmax(78px, 100%);
}

.width-col-reverse {
  min-width: minmax(78px, 100%);
}

.width-col-order {
  min-width: minmax(90px, 100%);
}

.home__reverses-layout {
  display: grid;
  grid-template-columns: minmax(128px, 1fr) 1fr 1fr 1fr;
}

.home__refunds-layout {
  display: grid;
  grid-template-columns: minmax(128px, 1fr) 1fr 1fr 1fr;
}

.logistics__products-table {
  display: grid;
  grid-template-columns: 32px minmax(64px, 1fr) 1fr 1fr 1fr 1fr 1fr 1fr minmax(250px, 1fr) !important;
}
