.mg-bottom-10 {
  margin-bottom: 10px;
}

.mg-bottom-15 {
  margin-bottom: 10px;
}

.mg-bottom-20 {
  margin-bottom: 20px;
}

.mg-bottom-30 {
  margin-bottom: 30px;
}

.mg-bottom-40 {
  margin-bottom: 40px;
}

.pd-bottom-10 {
  padding-bottom: 10px;
}

.pd-bottom-15 {
  padding-bottom: 10px;
}

.pd-bottom-20 {
  padding-bottom: 20px;
}

.pd-bottom-40 {
  padding-bottom: 40px;
}

.mg-top-10 {
  margin-top: 10px;
}

.mg-top-15 {
  margin-top: 15px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-top-30 {
  margin-top: 30px;
} 

.mg-top-40 {
  margin-top: 40px;
}

.mg-right-8 {
  margin-right: 8px;
}

.mg-right-10 {
  margin-right: 10px;
}

.mg-right-15 {
  margin-right: 10px;
}

.mg-right-20 {
  margin-right: 20px;
}

.mg-right-30 {
  margin-right: 30px;
}

.mg-left-10 {
  margin-left: 10px;
}

.mg-left-15 {
  margin-left: 10px;
}

.mg-left-20 {
  margin-left: 20px;
}

.mg-left-30 {
  margin-left: 30px;
}

.pd-top-10 {
  padding-top: 10px;
}

.pd-top-20 {
  padding-top: 20px;
}

.pd-top-30 {
  padding-top: 30px;
}

.pd-top-40 {
  padding-top: 40px;
}

.mg-10 {
  margin: 10px;
}

.mg-20 {
  margin: 20px;
}

.h-25 {
  height: 25px;
}

.h-50 {
  height: 50px;
}

.h-75 {
  height: 75px;
}

.mg-pd-bottom-disabled-last {
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.mg-pd-top-disabled-last {
  &:last-of-type {
    padding-top: 0;
    margin-top: 0;
  }
}
