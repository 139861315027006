// Flex Grid
.flex {
  display: flex;

  &.flex-center {
    justify-content: center;
    align-items: center;
  }

  &.flex-center-start {
    justify-content: center;
    align-items: start;
  }

  &.flex-end-center {
    justify-content: flex-end;
    align-items: center;
  }

  &.flex-around-center {
    justify-content: space-around;
    align-items: center;
  }

  &.flex-between-start {
    justify-content: space-between;
    align-items: flex-start;
  }

  &.flex-between-center {
    justify-content: space-between;
    align-items: center;
  }

  &.flex-start-column {
    justify-content: start;
    flex-direction: column;
  }

  &.flex-evenly {
    justify-content: space-evenly;
  }

  &.flex-evenly-center-column {
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  &.flex-center-column {
    justify-content: center;
    align-items: center;
    flex-direction: column
  }

  &.flex-column-center {
    flex-direction: column;
    justify-content: center;
  }

  &.flex-center-flex-end {
    justify-content: center;
    align-items: flex-end;
  }

  &.flex-vertical {
    flex-direction: column;
  }

  &.flex-vertical-inverted {
    flex-direction: column-reverse;
  }
}

.grid {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  box-sizing: border-box;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
  word-break: break-all;

  &.grid-gap-10 {
    grid-gap: 10px;
  }

  &.grid-gap-none {
    grid-gap: 0;
  }

  .self-center {
    align-self: center;
  }

  // Grid Columns
  &.grid-col-lg-1 {
    grid-template-columns: 1fr;
  }

  &.grid-row-lg-1 {
    grid-template-rows: 1fr;
  }

  &.grid-col-lg-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.grid-row-lg-2 {
    grid-template-rows: repeat(2, 1fr);
  }

  &.grid-col-lg-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.grid-col-lg-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.grid-col-lg-5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &.grid-col-lg-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  &.grid-col-lg-7 {
    grid-template-columns: repeat(7, 1fr);
  }

  &.grid-col-lg-9 {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  // Responsive Grid Columns
  @media screen and (max-width: 767.98px) {
    &.grid-col-xs-1 {
      grid-template-columns: 1fr;
    }

    &.grid-col-xs-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid-col-xs-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    &.grid-col-sm-1 {
      grid-template-columns: 1fr;
    }

    &.grid-col-sm-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid-col-sm-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-col-sm-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    &.grid-col-md-1 {
      grid-template-columns: 1fr;
    }

    &.grid-col-md-2 {
      grid-template-columns: repeat(2, 1fr);
    }

    &.grid-col-md-3 {
      grid-template-columns: repeat(3, 1fr);
    }

    &.grid-col-md-4 {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  // Grid Auto Placement Columns ( to childs )
  .grid-auto-placement {
    column-count: 1;
    grid-column: span 1;
    grid-row: span 2;
  }
}
