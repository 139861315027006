$primary: #4f008c;
$primary-dark: #3c006a;
$primary-darkless: #411076;
$primary-light: #874ae0;

$title-foreground: #192a3e;
$text-foreground: #686868;

// Design system
$app-background: #f5f6fa; // F5F6FA
$app-background-dark: #1a1a1a;
$card-background: #ffffff;
$puppy-purple: #8884d8;
$light-purple: #926bdb;
$purple: #62449a;
$dark-purple: #532f86;
$separator-color: #e6e8f1;

$text-color: #000;
$text-description-color: rgba(104, 104, 104, 0.6);
$text-error-color: #f44336;

$tf-green: #219653;
$tf-green-transparent: rgba(33, 150, 83, 0.1);
$tf-green-light: #b3e0d0;
$tf-blue: #2d9cdb;
$tf-blue-transparent: rgba(45, 156, 219, 0.1);
$tf-yellow: #ffd639;
$tf-yellow-transparent: rgba(255, 214, 57, 0.1);
$tf-coral: #ff4954;
$tf-coral-transparent: rgba(255, 73, 84, 0.1);
$tf-coral-light: #f7a8a2;
$tf-grey: #686868;
$tf-light-gray: rgba(104, 104, 104, 0.6);
$tf-light-white: rgba(255, 255, 255, 0.6);
$tf-light-black: rgba(0, 0, 0, 0.6);

$gray-01: #ddd;
$gray-02: #bbb;
$gray-03: #999;
$gray-04: #777;
$gray-05: #555;
$gray-06: #444;
$gray-07: #222;

$darkgray: #9f9fa0;
$dimgray: #585959;
$gold: #fcd43b;
$buddhagold: #cc9900;
$neoncarrot: #ff9934;
$coral: #ff794a;
$cerise: #de3163;
$salmon: #ff7b6e;
$sunsetorange: #ff4954;
$tiamaria: #ce2c0d;
$jacarta: #3d2766;
$royalpurple: #62449a;
$blue-magenta: #caadff;
$smalt: #003399;
$sanmarino: #4068aa;
$azureradiance: #0099ff;
$caribbeangreen: #00c4b1;
$japaneselaurel: #30d64b;
$camarone: #098f15;

$success: #009900;
$warning: #ff794a;
$danger: #ce2c0d;
$info: #003399;

// status colors
$status-other: brown;
