@import 'grid';
@import 'media';
@import 'z-index';

.layout-grid-horizontal-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 10px;
}

.layout-grid-horizontal-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  column-gap: 10px;
}
