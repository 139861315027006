@import '../base/sizes';

.modern-modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  background-color: var(--modal-background-color);
  color: var(--text-secondary-color);

  button {
    svg {
      height: 15px;
      margin-right: 5px;
    }
  }

  #container {
    position: relative;
    padding: 20px 20px;
    background-color: var(--card-background-color);
    border: none;
    border-radius: 4px;
    max-width: $modal-max-width;
    height: auto;
    max-height: 80%;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(157px);
    animation: entrance 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    // min-width: 520px;

    &[data-align-top='on'] {
      top: -36%;
    }

    &[data-enable-padding='off'] {
      padding: 0;
    }

    &[data-enable-shadow='off'] {
      box-shadow: none;
    }

    &[data-enable-background='off'] {
      background-color: transparent;
    }
  }
}

button.modern-modal__close-button {
  position: absolute;
  top: 50px;
  right: 50px;
  border: 0;
  background-color: transparent;
  color: var(--card-background-color);
  cursor: pointer;
}

@keyframes entrance {
  0% {
    transform: scale(0);
    transform-origin: 50% 0;
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0;
    opacity: 1;
  }
}
