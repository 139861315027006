@import '../../base/colors';
@import '../../base/sizes';

.aside-menu {
  width: 100%;

  &-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: $aside-bar-size;
    height: 64px;
    background-color: transparent;
    border-right: 1px solid transparent;
    transition: all .3s ease;
    position: relative;
    cursor: pointer;

    i {
      color: var(--separator-color);
      font-size: $title-font-size;
    }

    a {
      justify-items: center;
      text-align: center;
      background: none!important;
    }

    #hover-description {
      display: flex;
      position: absolute;
      background-color: var(--primary-color-light);
      color: var(--card-background-color);
      opacity: 0;
      padding: 12px;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      user-select: none;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
      z-index: 4096;
      width: 84px;
      text-overflow: ellipsis;
      top: 50%;
      cursor: pointer;
      transform: translate(-100%, -50%);
    }

    &:hover {
      transition: all .1s ease;
      border-right: 1px solid var(--primary-highlight);

      @media (min-width: $medium-width) {
        #hover-description {
          opacity: 1;
          animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        }
      }

    }

    &--active {
      background-color: var(--card-background-color);
      border-right: 2px solid var(--primary-color-light);

      i {
        color: var(--primary-color-light);
      }
    }
  }

  .nolink, a {
    color: var(--text-primary-color);
    font-size: $subtitle-font-size;
    cursor: pointer;

    .menu-icon {
      svg {
        fill: var(--darkgray-color);

        &[data-active="on"] {
          fill: var(--primary-color-light);
        }
      }
    }

    .menu-item-title {
      padding-left: 10px;
      display: inline-block;
    }

    .dropdown-icon {
      text-align: right;
      width: 20px;
      float: right;
    }

    .featured {
      background-color: var(--danger-color);
      border-radius: 20px;
      font-size: $subtext-font-size;
      padding: 3px 5px;
      float: right;
    }

    &.active {
      background-color: var(--primary-color-light);
    }
  }

  &-dropdown {

    &.active {
      background-color: var(--primary-color-dark);
    }

    .aside-menu-item {
      a {
        padding: 20px 20px 20px 65px;
        font-size: $text-font-size;
        padding-left: 5px;

        &.active {
          background-color: rgba(0,0,0,0.1);

        }
      }
    }
  }


  .no-toggled {

    .float-name {
      display: none;
    }

    .aside-menu-item, .nolink, .aside-menu-dropdown .aside-menu-item {

      span.fas {
        text-align: center;
        width: 100%;
        float: none;
      }

      a {
        padding: 15px !important;
        font-size: $subtitle-font-size;
      }

      &:hover {
        .float-name {
          display: block;
          position: absolute;
          z-index: 33;
          margin-left: 50px;
          margin-top: -30px;
          background-color: var(--primary-color-dark);
          border-radius: 5px;
          padding: 10px 15px;

          &:after {
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            left: -3px;
            top: 40%;
            background-color: var(--primary-color-dark);
            transform: rotate(135deg);
          }
        }
      }
    }
  }
}


@keyframes slide-in-left {
  0% {
    transform: translate(-32px, -50%);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(72px, -50%);
  }
}
