@import '../base/colors';
@import '../base/sizes';

$button-height: 36px;

.context-sensitive-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-width: 200px;

  &:last-of-type {
    margin-right: 8px;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $button-height;
    background-color: var(--card-background-color);
    color: var(--text-primary-color);
    border: 2px solid var(--separator-color);
    padding: 0 22px 0 22px;
    margin: 0 8px 0 8px;
    border-radius: 21px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    font-size: $text-font-size;
    outline: none;

    span {
      margin-right: 8px;
    }

    svg {
      margin-right: 8px;
      fill: var(--darkgray-color);
    }

    &:hover {
      transition: background-color 0.3s ease;
      background-color: var(--separator-color);
    }

    @media (max-width: $small-width) {
      padding: 0;
      height: $button-height;
      width: $button-height;

      span {
        margin: 0;
      }

      p {
        display: none;
      }
    }
  }

  &-circular-button {
    height: $button-height;
    width: $button-height;
    justify-content: center;
    align-items: center;
    background-color: var(--card-background-color);
    color: var(--text-primary-color);
    margin: 0 8px 0 8px;
    border: none;
    border-radius: 21px;
    font-weight: 800;
    cursor: pointer;
    transition: background-color 0.3s ease;
    outline: none;

    svg {
      fill: var(--darkgray-color);
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        fill: var(--header-disabled-color);
      }

      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      transition: background-color 0.3s ease;
      background-color: var(--separator-color);
    }
  }
}

.below-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--card-background-color);
  border-radius: 8px;
  min-width: 128px;
  padding: 8px;
  right: 1%;
  top: 100%;

  * {
    cursor: pointer;
    margin: 4px 0 4px 0;
    padding: 4px;
    border-radius: 4px;
    color: var(--text-secondary-color);

    &:hover {
      background-color: var(--primary-color);
      color: var(--card-background-color);
    }
  }
}

.menu-container {
  &__export {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--card-background-color);
    border-radius: 8px;
    min-width: 128px;
    padding: 8px;
    right: 1%;
    top: 100%;

    .button-export {
      cursor: pointer;
      margin: 4px 0 4px 0;
      padding: 4px;
      border-radius: 4px;
      color: var(--text-secondary-color);
      text-align: left;

      &:hover {
        background-color: var(--primary-color);
        color: var(--card-background-color);
      }
    }
  }
}
